// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import { GRID_REBUILD_EVENT } from 'shared/const';

// Store imports
// ================================================================
import { useDispatch } from 'react-redux';
import { updateColumnVisibility } from 'shared/Store/Columns';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';

// Component declaration
// ================================================================
export default function ColumnHideButton(props) {
  const { id } = props;

  const dispatch = useDispatch();

  const onClick = useCallback(async () => {
    await dispatch(updateColumnVisibility(id));
    const rebuildEvent = new Event(GRID_REBUILD_EVENT);
    document.body.dispatchEvent(rebuildEvent);
  }, [dispatch, id]);

  return (
    <Button
      appearance='solid'
      icon='hide'
      text='Hide'
      title='Hide this column'
      onClick={onClick}
    />
  );
}

// PropTypes Declaration
// ================================================================
ColumnHideButton.propTypes = {
  /** Unique column ID */
  id: PT.number.isRequired
};
