// Required imports
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';

// Component declaration
// ================================================================
export default function LoadMoreButton(props) {
  const { loading, idleText, loadingText, onClick, title } = props;
  return (
    <Button
      title={title}
      onClick={onClick}
      block
      appearance='solid'
      className='LoadMoreButton'
      loading={loading}
      text={loading ? loadingText : idleText}
    />
  );
}

// PropTypes Declaration
// ================================================================
LoadMoreButton.propTypes = {
  /** Handler to request loading by click */
  onClick: PT.func,
  /** Whatever loading is in process */
  loading: PT.bool,
  /** Title attibute of a button */
  title: PT.string,
  /** Text of a Button when no loading happening */
  idleText: PT.string,
  /** Text of a Button when loading is in process */
  loadingText: PT.string
};

LoadMoreButton.defaultProps = {
  idleText: 'Load more',
  loadingText: 'Loading...'
};
