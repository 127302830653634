// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import Icon from 'trendolizer-ui/build/Icon';

// Utility functions, constants and hooks
// ================================================================
import { DASHBOARD_DND_ID } from 'shared/const';

export default function ColumnCell(props) {
  const { id, dndEnabled, index, children, style, className, isDragDisabled } =
    props;
  if (dndEnabled) {
    return (
      <Draggable
        draggableId={`${DASHBOARD_DND_ID}-${index}`}
        isDragDisabled={isDragDisabled}
        index={index}
        key={id}
      >
        {(provided) => (
          <div
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              ...style
            }}
            data-index={index}
            data-column-id={id}
            ref={provided.innerRef}
            className={cn(className, {
              'state-disabled': isDragDisabled
            })}
          >
            {!isDragDisabled ? (
              <span {...provided.dragHandleProps} className='DragHandle'>
                <Icon icon='drag-handle' />
              </span>
            ) : null}
            {children}
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <div key={id} className={className} style={style} data-column-id={id}>
      {children}
    </div>
  );
}

// PropTypes declaration
// ================================================================
ColumnCell.propTypes = {
  /** CSS class for container <div> element */
  className: PT.string,
  /** Styles provided by 'react-virtualized' <Grid> component */
  style: PT.object.isRequired,
  /** Unique item ID */
  id: PT.number.isRequired,
  /** Index position of Column in a list */
  index: PT.number.isRequired,
  /** Whatever D&D enabled and item should be wrapped in <Draggable> */
  dndEnabled: PT.bool,
  /** Item content to render  */
  children: PT.node,
  /** Whatever this item is disabled for dragging */
  isDragDisabled: PT.bool
};
