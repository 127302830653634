import './style.css';

// Required libraries
// ================================================================
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PT from 'prop-types';

// Store and util imports
// ================================================================
import debounce from 'trendolizer-ui/build/util/debounce';

// Component imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import TextInput from 'trendolizer-ui/build/TextInput';
import Dialog from 'trendolizer-ui/build/Dialog';

import ResultList from './ResultList';

// Component declaration
// ================================================================
export default function SearchResults(props) {
  const { id } = props;

  const [show, setShow] = useState(false);

  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');

  const setQueryDebounced = useMemo(() => debounce(setQuery, 300), []);

  const closeDialog = useCallback(() => {
    setShow(false);
    setSearch('');
  }, []);

  useEffect(() => {
    setQueryDebounced(search);
  }, [setQueryDebounced, search]);

  return (
    <>
      <Button
        key='button'
        icon='search'
        text='Search'
        title='Search'
        className='SearchResults-button'
        onClick={useCallback(() => setShow(true), [])}
      />
      <Dialog
        key='dialog'
        id={id}
        show={show}
        maxWidth='52rem'
        maxHeight='90vh'
        title='Search for a result'
        className='SearchResults-dialog'
        onClose={closeDialog}
      >
        <form className='SearchResults-queryform'>
          <TextInput
            id='results-search'
            name='search'
            icon='search'
            type='text'
            value={search}
            placeholder='Search query....'
            className='SearchResults-searchinput'
            onChange={useCallback(({ value }) => setSearch(value), [])}
            message='Type to search across results on a dashboard'
          />
        </form>
        <ResultList
          query={query}
          className='SearchResults-resultlist'
          itemClassName='SearchResults-item'
          closeDialog={closeDialog}
        />
      </Dialog>
    </>
  );
}

SearchResults.propTypes = {
  id: PT.string.isRequired
};
