import React, { useContext, useEffect, useRef } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

import { mapGraphGoogle } from '../../Graph';
import { Context } from '../../Modules/GoogleProvider';
import StatusMessage from '../StatusMessage';

const options = {
  interpolateNulls: true,
  backgroundColor: 'transparent',
  theme: 'material',
  explorer: {
    actions: ['dragToZoom', 'rightClickToReset']
  },
  annotation: {
    style: 'line'
  },
  curveType: 'function',
  chartArea: {
    backgroundColor: 'transparent',
    top: 'auto',
    left: 80,
    height: '90%',
    width: '100%'
  },
  pointSize: 3,
  legend: {
    position: 'bottom'
  }
};

export default function Graph(props) {
  const {
    className,
    data,
    fields,
    children,
    nocount,
    norate,
    noaverage,
    nomovingaverage,
    nochangerate
  } = props;
  const $root = useRef();

  const { Api, error } = useContext(Context);

  useEffect(() => {
    if (data && $root && $root.current && Api) {
      const chart = new Api($root.current);
      /* window.google.visualization.events.addListener(chart, 'ready', () => {
        document.body.insertAdjacentHTML(
          'beforeend',
          '<img width="400px" height="400px" src="' + chart.getImageURI() + '">'
        );
      }); */
      chart.draw(
        mapGraphGoogle(data, fields, {
          nocount,
          norate,
          noaverage,
          nomovingaverage,
          nochangerate
        }),
        {
          ...options,
          width: $root.current.clientWidth,
          height: $root.current.clientHeight
        }
      );
    }
  }, [
    data,
    fields,
    Api,
    nocount,
    norate,
    noaverage,
    nomovingaverage,
    nochangerate
  ]);

  return (
    <div ref={$root} className={cn('Graph', className)}>
      {error ? <StatusMessage error={error} /> : children}
    </div>
  );
}

Graph.propTypes = {
  children: PT.node,
  data: PT.objectOf(PT.object),
  fields: PT.string,
  className: PT.string,
  nocount: PT.bool,
  norate: PT.bool,
  noaverage: PT.bool,
  nomovingaverage: PT.bool,
  nochangerate: PT.bool
};
