// Required libraries
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Util imports
// ================================================================
import { GRID_SCROLL_EVENT } from 'shared/const';
import { emitCustomEvent } from 'shared/util';

// Component imports
// ================================================================
import Image from 'trendolizer-ui/build/Image';
import DateTime from 'trendolizer-ui/build/DateTime';

// Component declaration
// ================================================================
export default function SearchResultItem(props) {
  const {
    className,
    truncatedTitle,
    url,
    hash,
    image,
    columns,
    found,
    likes,
    shares,
    tweets,
    comments,
    views_video,
    votes_video,
    comments_video,
    closeDialog
  } = props;

  return (
    <article key={hash} className={className}>
      <h1 className={`${className}-title`}>
        <a href={url} target='_blank' rel='noreferrer'>
          {truncatedTitle}
        </a>
      </h1>
      <Image
        className={`${className}-image`}
        src={image}
        alt={truncatedTitle}
      />
      <ul className={`${className}-details`}>
        {found ? (
          <li>
            <DateTime data={found} format='MMMM DD | HH:mm' icon='clock' />
          </li>
        ) : null}
        {likes ? <li>Likes: {likes}</li> : null}
        {shares ? <li>Shares: {shares}</li> : null}
        {tweets ? <li>Tweets: {tweets}</li> : null}
        {comments ? <li>Tweets: {tweets}</li> : null}
        {views_video ? <li>Video views: {views_video}</li> : null}
        {votes_video ? <li>Video views: {votes_video}</li> : null}
        {comments_video ? <li>Video views: {comments_video}</li> : null}
      </ul>
      <ul className={`${className}-footer`}>
        <li>Found in:</li>
        {columns.map(({ id, name, index, colIndex }) => (
          <li key={id}>
            <a
              onClick={(e) => {
                e.preventDefault();
                emitCustomEvent(GRID_SCROLL_EVENT, { colIndex, index, id });
                closeDialog();
              }}
            >
              {name}
            </a>
          </li>
        ))}
      </ul>
    </article>
  );
}

// PropTypes declaration
// ================================================================
SearchResultItem.propTypes = {
  className: PT.string.isRequired,
  truncatedTitle: PT.string.isRequired,
  url: PT.string.isRequired,
  hash: PT.string.isRequired,
  image: PT.string,
  found: PT.string,
  likes: PT.number,
  shares: PT.number,
  tweets: PT.number,
  comments: PT.number,
  views_video: PT.number,
  votes_video: PT.number,
  comments_video: PT.number,
  columns: PT.arrayOf(
    PT.shape({
      id: PT.number.isRequired,
      name: PT.string.isRequired,
      index: PT.number.isRequired,
      colIndex: PT.number.isRequired
    })
  ),
  closeDialog: PT.func.isRequired
};
