// Required libraries
// ================================================================
import React, { useCallback, useRef } from 'react';
import PT from 'prop-types';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';

// Utility functions, constants and hooks
// ================================================================
import { DASHBOARD_DND_ID } from 'shared/const';

// Component declaration
// ================================================================
export default function DragDrop(props) {
  const { onDragEnd, renderClone, children } = props;
  const $grid = useRef();

  const childrenRenderer = useCallback(
    ({ innerRef }) =>
      children((ref) => {
        if (ref) {
          $grid.current = ref;
          innerRef(ref._scrollingContainer);
        }
      }),
    [children]
  );

  const handleDragEnd = useCallback(
    async (provided) => {
      await onDragEnd(provided);
      if ($grid.current) {
        $grid.current.recomputeGridSize({ columnIndex: provided.source.index });
      }
    },
    [onDragEnd]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        droppableId={DASHBOARD_DND_ID}
        mode='virtual'
        direction='horizontal'
        renderClone={renderClone}
      >
        {childrenRenderer}
      </Droppable>
    </DragDropContext>
  );
}

// PropTypes declaration
// ================================================================
DragDrop.propTypes = {
  /** Function that invoked when D&D interaction is done, it should "save" the results */
  onDragEnd: PT.func,
  /** Function that renders "clone" item (attached to cursor) */
  renderClone: PT.func,
  /** Function that should render 'react-virtualized' <Grid> instance */
  children: PT.func.isRequired
};
