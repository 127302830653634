// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import cn from 'classnames';

// Utility functions and hooks
// ================================================================
import { numBool } from 'shared/proptypes';
import useCtxChannel from 'trendolizer-ui/build/hooks/useCtxChannel';
import { ROOT_ID, MANAGEMENT_URL, MANAGEMENT_PATHS } from 'shared/const';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import Icon from 'trendolizer-ui/build/Icon';

// Other components render
// ================================================================
import ColumnHeader from './Header';
import ColumnDetails from './Details';
import ColumnHideButton from './HideButton';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function Column(props) {
  const { children, data, onDeleteClick, refreshResults } = props;
  const { id, name, sort, infinite, direction, search } = data;

  // Show Details state and handlers
  // ================================================================
  const [open, setOpen] = useState(false);

  const closeDetails = useCallback(() => {
    setOpen(false);
  }, []);

  const dispatch = useCtxChannel(closeDetails);

  const openDetails = useCallback(
    (e) => {
      if (document.getElementById(ROOT_ID).contains(e.target)) {
        dispatch();
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
      }
    },
    [dispatch]
  );

  return (
    <section id={`column-${id}`} className='Column' tabIndex='0'>
      <ColumnHeader id={id} title={name} className='Column-heading'>
        {refreshResults ? (
          <Button
            icon='refresh'
            title='Click to refresh results'
            onClick={refreshResults}
          />
        ) : null}
        <Button
          className={cn({ 'state-active': open })}
          icon='menu'
          title='Show basic settings'
          onClick={openDetails}
        />
      </ColumnHeader>
      {open ? (
        <ColumnDetails
          className='Column'
          id={id}
          sort={sort}
          direction={direction}
          search={search}
          infinite={infinite}
          refreshResults={refreshResults}
          onBodyClick={closeDetails}
        >
          <ColumnHideButton id={id} />
          <a
            href={`${MANAGEMENT_URL}${MANAGEMENT_PATHS.COLUMNS}/${id}`}
            target='_blank'
            rel='noreferrer'
            className='Button is-solid Column-action-settings'
            title='Navigate to Full Settings'
          >
            <Icon icon='cog' />
            <span className='Button-text'>Settings</span>
          </a>
          <Button
            icon='trash'
            className='Column-action-delete dangerous-action'
            onClick={onDeleteClick}
            value={id}
            title='Delete this column'
          />
        </ColumnDetails>
      ) : null}
      <div className='Column-content'>{children}</div>
    </section>
  );
}

// PropTypes Declaration
// ================================================================
Column.propTypes = {
  /** Column properties needed for a dashboard interface */
  data: PT.shape({
    infinite: numBool.isRequired,
    direction: PT.oneOf(['asc', 'desc']).isRequired,
    sort: PT.string.isRequired,
    search: PT.string.isRequired,
    name: PT.string.isRequired,
    id: PT.number.isRequired
  }).isRequired,
  /** Handler "refresh results" button click */
  refreshResults: PT.func,
  /** Handle delete button click  */
  onDeleteClick: PT.func.isRequired,
  /** Content to render: presuming results list */
  children: PT.node
};
