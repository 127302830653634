// Required libraries
// ================================================================
import React from 'react';
import { Provider } from 'react-redux';

// Utility functions and constants
// ================================================================
import { set as setCssVar } from 'trendolizer-ui/build/util/cssVar';
import {
  BASE_URL,
  SCROLLBAR_CSS_VAR,
  MANAGEMENT_URL,
  FULL_RESULT_ID,
  SEARCH_RESULTS_DIALOG_ID,
  CREATE_COLUMN_DIALOG_ID,
  DASHBOARD_GRID_ID
} from 'shared/const';
import { getScrollbarWidth } from 'shared/util';

// Store imports
// ================================================================
import JsonpService from 'trendolizer-client/src/jsonp';
import ApiService from 'trendolizer-client/src/fetch';
import createAppStore from 'shared/Store';
import TENANT from 'shared/Store/Tenant';
import COLUMNS, { fetchColumns } from 'shared/Store/Columns';
import RESULTS from 'shared/Store/Results';
import DASHBOARDS, { fetchDashboards } from 'shared/Store/Dashboards';

// Loading init logic container
// ================================================================
import LoadingOverlayModule from 'shared/Modules/LoadingOverlay';
import GoogleProvider from 'shared/Modules/GoogleProvider';

// Navigation and sibebar modules
// ================================================================
import SidebarModule from 'shared/Modules/Sidebar';
import DashboardNavModule from 'shared/Modules/DashboardNav';
import ManagementNavModule from 'shared/Modules/ManagementNav';
import NavigationHref from 'shared/Components/NavigationList/Href';

// Application Tenant related modules
// ================================================================
import LoginDialogModule from 'shared/Modules/LoginDialog';
import TenantSettingsModule from 'shared/Modules/TenantSettings';

// Notifications container
// ================================================================
import NotificationsModule from 'shared/Modules/Notifications';

// Dashboards and Columns containers
// ================================================================
import DashboardContainer from './Dashboard';
import FullResultDialog from './FullResultDialog';
import CreateColumn from './CreateColumn';
import SearchResults from './SearchResults';

// Import style files
// ================================================================
import 'public/css/dashboard.css';

// Set current scrollbarsize as css var
// ================================================================
setCssVar(SCROLLBAR_CSS_VAR, `${getScrollbarWidth()}px`);

// Create Redux store, provide config and deps
// ================================================================
const store = createAppStore({
  api: new JsonpService({ url: BASE_URL }),
  apiv2: new ApiService({
    url: BASE_URL,
    extend: {
      async getOrCreateItem(key, fallback) {
        try {
          const data = await this.get('getItem', { key });
          return data.value ? JSON.parse(data.value) : data || fallback;
        } catch (err) {
          if (
            err.message !== `Key: ${key} is not initialized. Creating it now...`
          ) {
            throw err;
          }
          await this.post('setItem', {
            key,
            value: fallback
          });
          return fallback;
        }
      },
      async setItem(key, value) {
        await this.post('setItem', {
          key,
          value
        });
        return value;
      }
    }
  }),
  modules: [TENANT, DASHBOARDS, COLUMNS, RESULTS]
});

// Loading steps
// ================================================================
const LOADING_STEPS = [
  { label: 'Checking dashboards', handler: fetchDashboards },
  { label: 'Retrieving column info', handler: fetchColumns }
];

export default (
  <GoogleProvider pack='corechart' apiGetter='google.visualization.LineChart'>
    <Provider store={store}>
      <LoadingOverlayModule steps={LOADING_STEPS} />
      <SidebarModule className='application-screen-aside'>
        <SearchResults id={SEARCH_RESULTS_DIALOG_ID} />
        <CreateColumn id={CREATE_COLUMN_DIALOG_ID} />
        <DashboardNavModule component={NavigationHref} />
        <ManagementNavModule component={NavigationHref} base={MANAGEMENT_URL} />
      </SidebarModule>
      <DashboardContainer
        id={DASHBOARD_GRID_ID}
        dndEnabled
        className='DashboardScreen-container'
      />
      <TenantSettingsModule />
      <LoginDialogModule />
      <FullResultDialog dialogId={FULL_RESULT_ID} />
      <NotificationsModule />
    </Provider>
  </GoogleProvider>
);
