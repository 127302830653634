// Required libraries
// ================================================================
import React, { useCallback } from 'react';
import PT from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Store imports
// ================================================================
import { useSimpleState, useReduxAction } from 'shared/hooks';
import {
  getCurrentDashboard,
  sortColumns,
  updateDashboard,
  saveColumnOrder
} from 'shared/Store/Dashboards';
import { getColumnsById, deleteColumn } from 'shared/Store/Columns';

// Component imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import DeleteConfirmation from 'shared/Components/DeleteConfirmation';
import DeleteColumnConfirmationMessage from 'shared/Components/DeleteConfirmation/ColumnMessage';
import ColumnsGrid from '../ColumnsGrid';
import ColumnContainer from '../ColumnContainer';
import NoContentRenderer from './NoContent';
import { useEffect } from 'react';

const mapWithStatus = ({ id, open, name }) => ({ id, open, name });

// Component declaration
// ================================================================
export default function DashboardContainer(props) {
  const { dndEnabled, id, className } = props;
  // Get current dashboard data
  // ================================================================
  const dashboard = useSelector(getCurrentDashboard);
  const columns = useSelector(
    getColumnsById(dashboard ? dashboard.columns : [], mapWithStatus)
  );
  const dispatch = useDispatch();

  const [deletePending, setDeletePending, resetDeletePending] =
    useSimpleState(null);

  const deleteHandler = useReduxAction(deleteColumn, dispatch, {
    id: deletePending
  });

  const assignColumns = useCallback(
    async ({ value }) =>
      dispatch(updateDashboard({ ...dashboard, columns: value })),
    [dispatch, dashboard]
  );

  // Dispatch API action, save new column order within current dashboard
  // ================================================================
  const onDragEnd = useCallback(
    ({ source, destination }) => {
      const params = {
        start: source.index,
        id: dashboard.id || 0,
        end: destination.index
      };
      dispatch(sortColumns(params));
      dispatch(saveColumnOrder(params));
    },
    [dispatch, dashboard.id]
  );

  useEffect(() => {
    document.title = dashboard.name;
  }, [dashboard.name]);

  if (!dashboard) return null;

  return (
    <section className={className} role='main'>
      {dashboard ? (
        <ColumnsGrid
          dndEnabled={dndEnabled}
          id={id}
          count={columns.length}
          onDragEnd={onDragEnd}
          noContentRenderer={() => (
            <NoContentRenderer
              className='DashboardScreen-noresults'
              onSubmit={assignColumns}
            />
          )}
          colWidth={({ index }) => {
            const { open } = columns[index];
            return open ? dashboard.width || 0 : 0;
          }}
          estimatedColumnSize={dashboard.width}
          renderClone={({ index }) => {
            const { id, open, name } = columns[index];
            return open ? (
              <section id={id} className='Column' tabIndex='0'>
                <header className='Column-heading'>
                  <h5 className='Column-heading-title'>{name}</h5>
                  <Button icon='menu' title='Show basic settings' />
                  <Button icon='refresh' title='Click to refresh data' />
                </header>
              </section>
            ) : null;
          }}
          renderItem={({ index }) => {
            const { id, open } = columns[index];
            return (
              <ColumnContainer
                id={id}
                open={open}
                width={dashboard.width || 0}
                onDeleteClick={setDeletePending}
              />
            );
          }}
        />
      ) : null}
      <DeleteConfirmation
        show={!!deletePending}
        onSubmit={deleteHandler}
        onCancel={resetDeletePending}
      >
        <DeleteColumnConfirmationMessage id={deletePending} />
      </DeleteConfirmation>
    </section>
  );
}

// PropTypes declaration
// ================================================================
DashboardContainer.propTypes = {
  /** CSS class name for root <section> */
  className: PT.string,
  /** Whatever D&D interactions should be included in app flow */
  dndEnabled: PT.bool,
  /** Unique id for <Grid> element. Mandatory param */
  id: PT.string.isRequired
};
