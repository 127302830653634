// Required imports
// ================================================================
import React from 'react';
import PT from 'prop-types';

// Trendolizer UI imports
// ================================================================
import CircularProgress from 'trendolizer-ui/build/CircularProgress';

// Style imports
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function InfiniteLoading({ text }) {
  return (
    <div className='InfiniteLoading'>
      <CircularProgress className='InfiniteLoading-progress' />
      <h4 className='InfiniteLoading-text'>{text}</h4>
    </div>
  );
}

// PropTypes Declaration
// ================================================================
InfiniteLoading.propTypes = {
  /** Text explaining that loading is in progress */
  text: PT.string
};
