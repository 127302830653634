import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import get from 'trendolizer-ui/build/util/get';

export const Context = React.createContext('GoogleApi');

const errorMessage =
  'For graphs, Google charts api is utilized. Looks like it wasn&apos;t included on page. Because we was unable to find required methods. Please include <script src="https://www.gstatic.com/charts/loader.js"></script> in page <head>.';

export default function GoogleProvider(props) {
  const { children, pack, apiGetter } = props;
  const [api, setApi] = useState(null);

  const apiFound = !!window.google;

  if (!apiFound) {
    console.error(
      'Google loader not found. Please include <script src="https://www.gstatic.com/charts/loader.js"></script> in page <head>.'
    );
  }

  useEffect(() => {
    if (window.google) {
      window.google.charts.load('current', { packages: [pack] });
      window.google.charts.setOnLoadCallback(() =>
        setApi(() => get(window, apiGetter))
      );
    }
  }, [pack, apiGetter]);
  return (
    <Context.Provider
      value={{
        error: !apiFound ? errorMessage : null,
        Api: api
      }}
    >
      {children}
    </Context.Provider>
  );
}

GoogleProvider.propTypes = {
  children: PT.node,
  pack: PT.string,
  apiGetter: PT.string
};
