// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

// Utility functions and hooks
// ================================================================
import invert from 'trendolizer-ui/build/util/invert';

// Component imports
// ================================================================
import ColumnNameInput from './NameInput';

// Component declaration
// ================================================================
export default function ColumnHeader(props) {
  const { id, title, children, className } = props;

  // Title editing state and handlers
  // ================================================================
  const [isEditMode, setEditMode] = useState(false);
  const toggleEditing = useCallback(() => setEditMode(invert), []);

  return (
    <header className={className}>
      <h5
        className={`${className}-title`}
        title={
          isEditMode
            ? 'Press enter to commit changes, escape to cancel'
            : 'Double click to edit column name'
        }
        onDoubleClick={toggleEditing}
      >
        {isEditMode ? (
          <ColumnNameInput
            id={id}
            name='name'
            initValue={title}
            onCancel={toggleEditing}
            className={`${className}-input`}
          />
        ) : (
          <span className={`${className}-text`}>{title}</span>
        )}
      </h5>
      {children}
    </header>
  );
}

// PropTypes Declaration
// ================================================================
ColumnHeader.propTypes = {
  /** Column ID */
  id: PT.number.isRequired,
  /** CSS class for root element */
  className: PT.string.isRequired,
  /** Name of column - main data to show */
  title: PT.string.isRequired,
  /** Additional control buttons */
  children: PT.node
};
