// Required libraries
// ================================================================
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PT from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import CircularProgress from 'trendolizer-ui/build/CircularProgress';

import { MULTI_DISPLAY_PARAMS } from '../../DisplaySettings';
import { fetchGraphData } from '../../Store/Results';
import StatusMessage from '../../Components/StatusMessage';
import Graph from '../../Components/Graph';

// Styles
// ================================================================
import './style.css';

const NOOP = {};

// Component declaration
// ================================================================
export default function ResultGraphs(props) {
  const {
    hash,
    fields,
    className,
    nocount,
    norate,
    noaverage,
    nomovingaverage,
    nochangerate
  } = props;
  const oldFields = useRef();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [apiError, setApiError] = useState(null);

  const fetchData = useCallback(
    async ({ hash, fields }) => {
      try {
        const { payload } = await dispatch(
          fetchGraphData({ hash, type: fields })
        );
        setData(payload);
        oldFields.current = fields;
      } catch (err) {
        setApiError(err.toString());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!oldFields.current || fields.length > oldFields.current.length) {
      fetchData({ hash, fields });
    }
  }, [hash, fields, fetchData]);

  return (
    <div className={cn('ResultGraphs', className)}>
      {apiError ? <StatusMessage error={apiError} /> : null}
      <Graph
        data={data || NOOP}
        fields={fields}
        nocount={nocount}
        norate={norate}
        noaverage={noaverage}
        nomovingaverage={nomovingaverage}
        nochangerate={nochangerate}
      >
        <div className='ResultGraphs-loading-text'>
          <CircularProgress className='ResultGraphs-loading' />
          Loading Graphs...
        </div>
      </Graph>
    </div>
  );
}

ResultGraphs.defaultProps = {
  fields: MULTI_DISPLAY_PARAMS.join(',')
};

ResultGraphs.propTypes = {
  className: PT.string,
  hash: PT.string.isRequired,
  fields: PT.string,
  nocount: PT.bool,
  norate: PT.bool,
  noaverage: PT.bool,
  nomovingaverage: PT.bool,
  nochangerate: PT.bool
};
