// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

// Utility functions and hooks
// ================================================================
import { useAction } from 'shared/hooks';

// Trendolizer UI imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import TextInput from 'trendolizer-ui/build/TextInput';
import Dialog from 'trendolizer-ui/build/Dialog';

// Component imports
// ================================================================
import StatusMessage from 'shared/Components/StatusMessage';
import SortingDropdown from 'shared/Components/SortingDropdown';
import DirectionIndicator from 'shared/Components/DirectionIndicator';
import { useEffect } from 'react';

const INIT = { name: '', search: '', sort: 'likes', direction: 'desc' };

// Component declaration
// ================================================================
export default function CreateColumnDialog(props) {
  const { id, show, onClose, onSubmit } = props;

  const [values, setValues] = useState(INIT);

  const [submit, { loading, error, success, reset, result }] = useAction(
    onSubmit,
    { onSuccess: onClose }
  );

  const setValue = useCallback(
    ({ name, value }) => setValues((old) => ({ ...old, [name]: value })),
    []
  );

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      return submit({
        name: values.name,
        data: {
          sort: values.sort,
          direction: values.direction,
          search: values.search
        }
      });
    },
    [submit, values]
  );

  useEffect(() => {
    if (show) {
      setValues({ ...INIT });
      reset();
    }
  }, [show, reset]);

  return (
    <Dialog
      id={id}
      show={show}
      className='CreateColumn-Dialog'
      maxWidth='480px'
      maxHeight='620px'
      title='New column'
      onClose={onClose}
      footerCloseButton
      footer={
        <Button
          appearance='accent'
          text='Create'
          form={id}
          onClick={submitHandler}
          loading={loading}
        />
      }
    >
      <form id={id} onSubmit={submitHandler} className='layout-grid'>
        {error || success ? (
          <div className='layout-cell'>
            <StatusMessage
              warning={error}
              success={success && result ? result.message : null}
              onClick={reset}
            />
          </div>
        ) : null}
        <div className='layout-cell'>
          <TextInput
            id='name'
            name='name'
            type='text'
            label='Name'
            value={values.name}
            onChange={setValue}
            disabled={loading}
            placeholder='Enter new column name'
          />
        </div>
        <div className='layout-cell'>
          <TextInput
            id='search'
            name='search'
            type='text'
            label='Search for'
            value={values.search}
            onChange={setValue}
            disabled={loading}
            message='Comma-separated list of terms that should exist in result title or description.'
          />
        </div>
        <div className='layout-cell'>
          <h4 className='label-replacement'>Sort by</h4>
          <div className='CreateColumn-Dialog-sorting'>
            <SortingDropdown
              id='sort'
              name='sort'
              value={values.sort}
              disabled={loading}
              onChange={setValue}
            />
            <DirectionIndicator
              id='direction'
              name='direction'
              onChange={setValue}
              disabled={loading}
              value={values.direction}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}

// PropTypes Declaration
// ================================================================
CreateColumnDialog.propTypes = {
  id: PT.string.isRequired,
  show: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired
};
