// Required libraries
// ================================================================
import React, { memo } from 'react';
import PT from 'prop-types';
import { useSelector } from 'react-redux';

// Store and util imports
// ================================================================
import { getResultsByText } from 'shared/Store/Results';

// Component imports
// ================================================================
import SearchResultItem from './ResultItem';

// Component declaration
// ================================================================
const ResultList = memo(function ResultList(props) {
  const { query, className, itemClassName, closeDialog } = props;

  const data = useSelector(getResultsByText(query));
  return (
    <div className={className}>
      {data.map(({ item, columns }) => {
        return (
          <SearchResultItem
            key={item.hash}
            {...item}
            columns={columns}
            className={itemClassName}
            closeDialog={closeDialog}
          />
        );
      })}
    </div>
  );
});

// PropTypes declaration
// ================================================================
ResultList.propTypes = {
  query: PT.string,
  className: PT.string,
  itemClassName: PT.string,
  closeDialog: PT.func.isRequired
};

export default ResultList;
