// Required libraries
// ================================================================
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';

// Utility functions and constants
// ================================================================
import { ROOT_ID } from 'shared/const';

import APP from './App';

// Render application
// ================================================================`
const root = createRoot(document.getElementById(ROOT_ID));
root.render(APP);
