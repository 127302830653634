// Required libraries
// ================================================================
import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

// Store imports
// ================================================================
import { useDispatch } from 'react-redux';
import { updateColumnName } from 'shared/Store/Columns';

// Trendolizer UI imports
// ================================================================
import TextInput from 'trendolizer-ui/build/TextInput';

// Component declaration
// ================================================================
export default function ColumnNameInput(props) {
  const { id, name, initValue, onCancel, className } = props;

  const [value, setValue] = useState(initValue);

  const dispatch = useDispatch();

  const onInputChange = useCallback(({ value }) => setValue(value), []);

  const onInputKeyUp = useCallback(
    async ({ target, key }) => {
      if (key === 'Enter') {
        await dispatch(updateColumnName({ id, name: target.value }));
        onCancel();
      } else if (key === 'Escape') {
        onCancel();
      }
    },
    [onCancel, id, dispatch]
  );

  return (
    <TextInput
      id={name}
      name={name}
      value={value}
      type='text'
      autoFocus
      onChange={onInputChange}
      onBlur={onCancel}
      onKeyUp={onInputKeyUp}
      message='Hit "Enter" to change column name. Hit "Escape" or click elsevere on a page to cancel.'
      className={className}
    />
  );
}

// PropTypes Declaration
// ================================================================
ColumnNameInput.propTypes = {
  /** Unique column ID */
  id: PT.number.isRequired,
  /** Name for an input field */
  name: PT.string.isRequired,
  /** Initial column value */
  initValue: PT.string,
  /** Handler invoked when name change cancelled */
  onCancel: PT.func.isRequired,
  /** Classname for root CSS element  */
  className: PT.string.isRequired
};

ColumnNameInput.defaultProps = {
  initValue: ''
};
