// Required libraries
// ================================================================
import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import runIfExists from 'trendolizer-ui/build/util/runIfExists';

// Store imports
// ================================================================
import { getCurrentDashboard, updateDashboard } from 'shared/Store/Dashboards';
import { createColumn } from 'shared/Store/Columns';

// Component imports
// ================================================================
import Button from 'trendolizer-ui/build/Button';
import CreateColumnDialog from './CreateColumDialog';

// Styles
// ================================================================
import './style.css';

// Component declaration
// ================================================================
export default function CreateColumn(props) {
  const { id, onCreated } = props;
  const dashboard = useSelector(getCurrentDashboard);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (params) => {
      const { payload } = await dispatch(createColumn(params));
      await dispatch(
        updateDashboard({
          ...dashboard,
          columns: [payload.id, ...dashboard.columns]
        })
      );
      runIfExists(onCreated);
    },
    [dispatch, dashboard, onCreated]
  );

  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        key='button'
        icon='plus'
        text='Add Column'
        title='New Column'
        className='CreateColumn-button'
        appearance='accent'
        onClick={useCallback(() => setShow(true), [])}
      />
      <CreateColumnDialog
        key='dialog'
        id={id}
        show={show}
        onSubmit={onSubmit}
        onClose={useCallback(() => setShow(false), [])}
      />
    </>
  );
}

// PropTypes Declaration
// ================================================================
CreateColumn.propTypes = { id: PT.string.isRequired, onCreated: PT.func };
